import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

export const VehicleModelCreate = props => {
  return (
    <Create title="Create Vehicle Model" {...props}>
      <SimpleForm>
        <TextInput source="make" fullWidth />

        <TextInput source="model" fullWidth />

        <TextInput source="year" fullWidth />
      </SimpleForm>
    </Create>
  );
};
