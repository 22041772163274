import React from "react";
import {
  ReferenceManyField,
  Datagrid,
  TextField,
  Pagination,
  DeleteButton,
  addField
} from "react-admin";
import { Avatar, Typography, Chip, Grid } from "@material-ui/core";
import { DocumentGrid } from "./document-grid";
import * as Compress from "client-compress";
import { Photo, Camera } from "@material-ui/icons";

const compressor = new Compress({ quality: 0.6 });

export const AvatarField = ({ record, source, size = "small" }) => {
  const { name, installationStatus } = record;

  const avatarStyles = {
    small: {
      margin: 10,
      width: 48,
      height: 48,
      borderColor: installationStatus === "uninstalled" ? "gray" : "teal",
      borderWidth: "2px",
      borderStyle: "solid"
    },
    huge: {
      margin: 10,
      width: 240,
      height: 240,
      borderColor: installationStatus === "uninstalled" ? "gray" : "teal",
      borderWidth: "8px",
      borderStyle: "solid"
    }
  };

  if (record[source]) {
    return (
      <a href={record["source"]}>
        <Avatar src={record[source]} style={avatarStyles[size]} />
      </a>
    );
  }

  return (
    <Avatar style={avatarStyles[size]}>{name ? name.charAt(0) : "A"}</Avatar>
  );
};

export const RoleField = ({ record = {} }) => {
  const role = record["role"];
  const status = role === "driver" ? record["driverStatus"] + " " + role : role;
  return (
    <>
      <Typography variant="body1">{status?.toUpperCase()}</Typography>
    </>
  );
};

RoleField.defaultProps = {
  addLabel: true
};

export const DocumentsField = props => {
  if (props.record && props.record.role === "driver") {
    return (
      <>
        <Typography variant="caption" style={{ margin: "24px 0 16px" }}>
          Documents
        </Typography>
        <ReferenceManyField
          {...props}
          pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
          reference="admin/documents"
          target="userId"
        >
          <DocumentGrid />
        </ReferenceManyField>
      </>
    );
  }

  return <div />;
};

export const VehiclesField = props => {
  if (props.record && props.record.role === "driver") {
    return (
      <>
        <Typography variant="caption" style={{ margin: "24px 0 16px" }}>
          Vehicles
        </Typography>
        <ReferenceManyField
          {...props}
          pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
          reference="admin/vehicles"
          target="userId"
        >
          <Datagrid rowClick="show">
            <TextField source="numberPlate" />
          </Datagrid>
        </ReferenceManyField>
      </>
    );
  }

  return <div />;
};

export const CommutesField = props => (
  <>
    <Typography variant="caption" style={{ margin: "24px 0 16px" }}>
      Commutes
    </Typography>
    <ReferenceManyField
      {...props}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      reference="admin/commutes"
      target="userId"
    >
      <Datagrid rowClick="show">
        <TextField label="As" source="type" />
        <TextField label="From" source="originAddress" />
        <TextField label="To" source="destinationAddress" />
        <TextField source="timeLeavingOrigin" label="Set Off" />
        <TextField source="timeLeavingDestination" label="Return" />
        <DeleteButton />
      </Datagrid>
    </ReferenceManyField>
  </>
);

export const ArrayStringField = ({ source, record }) => {
  return (
    <>
      {record[source].map((src, index) => (
        <Chip
          style={{ marginLeft: "3px", marginTop: "1px" }}
          key={index}
          label={src.locationAddress}
          variant="outlined"
        />
      ))}
    </>
  );
};

export const ImageInput = addField(
  ({ input, meta: { touched, error } = {}, label }) => {
    function onCapture() {
      const uploadInput = document.createElement("input");
      uploadInput.type = "file";
      uploadInput.accept = "image/*";
      uploadInput.onchange = handleUpload;
      uploadInput.click();
    }

    function handleUpload(e) {
      compressor
        .compress(Array.from(e.target.files))
        .then(([avatar]) => {
          return Compress.blobToBase64(avatar.photo.data);
        })
        .then(base64Url => {
          input.onChange(base64Url);
        });
    }

    return (
      <Grid
        justify="center"
        alignItems="center"
        style={{ paddingBottom: "2em" }}
        container
      >
        <Grid style={{ display: "flex", flexDirection: "column" }} item xs={12}>
          <Typography variant="caption">
            {touched && error ? error : label}
          </Typography>
          {input.value ? (
            <img
              alt={label}
              src={input.value}
              style={{ width: "100%" }}
              onClick={onCapture}
              variant="square"
            />
          ) : (
            <Avatar
              style={{ width: "100%", height: 240 }}
              onClick={onCapture}
              variant="square"
            >
              <Photo style={{ fontSize: 64 }} />
            </Avatar>
          )}
        </Grid>
      </Grid>
    );
  }
);

export const CameraInput = addField(
  ({ input, meta: { touched, error } = {}, label, ...all }) => {
    function onCapture() {
      const uploadInput = document.createElement("input");
      uploadInput.type = "file";
      uploadInput.accept = "image/*";
      uploadInput.capture = "environment";
      uploadInput.onchange = handleUpload;
      uploadInput.click();
    }

    function handleUpload(e) {
      compressor
        .compress(Array.from(e.target.files))
        .then(([avatar]) => {
          return Compress.blobToBase64(avatar.photo.data);
        })
        .then(base64Url => {
          input.onChange(base64Url);
        });
    }

    return (
      <Grid
        justify="center"
        alignItems="center"
        style={{ paddingBottom: "2em" }}
        container
      >
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
          }}
          item
          xs={12}
        >
          {input.value ? (
            <Avatar
              src={input.value}
              style={{ width: 240, height: 240 }}
              onClick={onCapture}
            />
          ) : (
            <Avatar style={{ width: 240, height: 240 }} onClick={onCapture}>
              <Camera style={{ fontSize: 64 }} />
            </Avatar>
          )}
          <Typography variant="caption">
            {touched && error ? error : label}
          </Typography>
        </Grid>
      </Grid>
    );
  }
);
