import React from "react";
import { Grid } from "@material-ui/core";
import { Map } from "./map";

export const MapGrid = props => {
  const locationUpdates = Object.keys(props.data || {}).map(
    key => props.data[key]
  );
  return (
    <Grid>
      <Grid item>
        {locationUpdates.length > 0 && <Map points={locationUpdates} />}
      </Grid>
    </Grid>
  );
};
