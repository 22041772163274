import React, { useState, useEffect, useRef, useMemo } from "react";
import { stringify } from "querystring";
import { Autocomplete } from "@material-ui/lab";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import {
  Grid,
  Typography,
  makeStyles,
  TextField,
  Box
} from "@material-ui/core";
import throttle from "lodash/throttle";
import { useInput } from "react-admin";

import { googleMapsApi } from "../config";
import { geocode } from "../utils/geocode";

const key = googleMapsApi;
const libraries = "places,geocode";

function loadScript(src, position, id) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    position.appendChild(script);
  });
}

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  }
}));

export const GoogleMapsAutocompleteLoader = () => {
  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?${stringify({
        key,
        libraries
      })}`,
      document.querySelector("head"),
      "google-maps"
    );
  }, []);

  return <div />;
};

export default function GoogleMaps(props) {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired
  } = useInput(props);
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [disabled, setDisable] = useState(true);
  const autocompleteService = useRef();

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        if (!autocompleteService.current) return Promise.resolve([]);
        return autocompleteService.current?.getPlacePredictions(
          request,
          callback
        );
      }, 200),
    [autocompleteService]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (window?.google?.maps?.places) {
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
        clearInterval(interval);
        setDisable(false);
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (inputValue === "") {
      setOptions([]);
    }

    if (inputValue) {
      fetch({ input: inputValue }, results => {
        setOptions(results?.map(result => result.description) || []);
      });
    }
  }, [inputValue, fetch]);

  useEffect(() => {
    if (rest.value) {
      setInputValue(rest.value.address);
      setOptions([rest.value.address]);
    }
  }, [rest.value]);

  return (
    <>
      <Autocomplete
        id="google-map"
        fullWidth
        disabled={disabled}
        getOptionLabel={option =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={x => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={inputValue}
        onChange={(event, newValue) => {
          if (newValue)
            geocode(newValue)
              .then(location => {
                onChange({ ...location, address: newValue });
              })
              .catch(error => console.error(error.message));
        }}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={params => {
          return (
            <TextField
              {...params}
              name={name}
              error={!!(touched && error)}
              helperText={touched && error}
              required={isRequired}
              label={props.label}
              fullWidth
              variant="outlined"
            />
          );
        }}
        renderOption={option => {
          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                <Typography>{option}</Typography>
              </Grid>
            </Grid>
          );
        }}
      />
      <Box marginBottom={3} />
    </>
  );
}
