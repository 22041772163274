import React from "react";
import {
  SimpleForm,
  Edit,
  TextInput,
  RadioButtonGroupInput
} from "react-admin";

export const TripEdit = props => (
  <Edit title={"Edit Trip"} {...props}>
    <SimpleForm>
      <TextInput disabled label="Trip_Id" source="id" />
      <RadioButtonGroupInput
        label="status"
        source="status"
        choices={[{ id: "cancelled", name: "cancel" }]}
      />
      <TextInput label="Cancellation Reason" source="cancelReason" />
    </SimpleForm>
  </Edit>
);
