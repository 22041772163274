import React from "react";
import { Datagrid, DateField, List, TextField } from "react-admin";
import { AvatarField } from "../components/fields";

export const EventList = props => (
  <List
    {...props}
    title="Prices"
    bulkActionButtons={false}
    sort={{ field: "createdAt", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <AvatarField source="logo" />
      <TextField source="name" />
      <TextField label="Venue" source="locationAddress" />
      <DateField source="start" showTime />
      <DateField source="end" showTime />
    </Datagrid>
  </List>
);
