export const statuses = [
  "pending",
  "accepted",
  "rejected",
  "cancelled-by-driver",
  "cancelled-by-passenger",
  "picked-up",
  "dropped-off"
];

export const statusOptions = statuses.map(status => ({
  id: status,
  name: status
}));
