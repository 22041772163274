import * as React from "react";
import { GridShowLayout, RaGrid } from "ra-compact-ui";
import { Show } from "react-admin";
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import { Phone, LocationOn } from "@material-ui/icons";

import { AvatarField } from "../components/fields";
import moment from "moment";

export const RequestShow = props => {
  return (
    <Show {...props} title="Request">
      <GridShowLayout>
        <RaGrid container direction="column" spacing={2}>
          <RaGrid item>
            <Details />
          </RaGrid>
          <RaGrid item>
            <RaGrid container spacing={2}>
              <RaGrid xs="12" sm={6} item>
                <User title="Passenger" role="passenger" />
              </RaGrid>
              <RaGrid xs="12" sm={6} item>
                <User title="Driver" role="driver" />
              </RaGrid>
            </RaGrid>
          </RaGrid>
          <RaGrid item>
            <Route />
          </RaGrid>
        </RaGrid>
      </GridShowLayout>
    </Show>
  );
};

const User = ({ record, title, role }) => {
  const user = role === "passenger" ? record.user : record.trip?.driver;

  if (!user) return <div />;

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Grid container alignItems="center" spacing={2} wrap="nowrap">
          <Grid item>
            <AvatarField record={user} source="avatar" size="small" />
          </Grid>

          <Grid item style={{ flex: "1 1 auto" }}>
            <Typography>{user?.name}</Typography>
          </Grid>

          <Grid item>
            <IconButton color="primary" href={`tel://${user?.phone}`}>
              <Phone />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Details = ({ record }) => {
  const vehicle = record.trip?.vehicle;
  return (
    <Card>
      <CardHeader title={"Details"} />
      <CardContent>
        <Grid container spacing="2">
          <Grid item xs="6" sm="4" md="3">
            <Chip label={record.status?.toUpperCase()} />
          </Grid>
          <Grid item xs="6" sm="4" md="3">
            <Typography variant="caption">ID</Typography>
            <Typography variant="body1">{record.id}</Typography>
          </Grid>
          <Grid item xs="6" sm="4" md="3">
            <Typography variant="caption">Price</Typography>
            <Typography variant="body1">UGX {record.price}</Typography>
          </Grid>
          <Grid item xs="6" sm="4" md="3">
            <Typography variant="caption">Requested At</Typography>
            <Typography variant="body1">
              {moment(record.createdAt).fromNow()}
            </Typography>
          </Grid>
          <Grid item xs="6" sm="4" md="3">
            <Typography variant="caption">Pick Up Time</Typography>
            <Typography variant="body1">
              {moment(record.pickupTime).fromNow()}
            </Typography>
          </Grid>
          <Grid item xs="6" sm="4" md="3">
            <Typography variant="caption">Vehicle Model</Typography>
            <Typography variant="body1">
              {vehicle?.vehicle?.make} {vehicle?.vehicle?.model}{" "}
              {vehicle?.vehicle?.year}
            </Typography>
          </Grid>
          <Grid item xs="6" sm="4" md="3">
            <Typography variant="caption">License Plate</Typography>
            <Typography variant="body1">{vehicle?.numberPlate}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const Route = ({ record }) => {
  return (
    <Card>
      <CardHeader title="Route" />
      <CardContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <LocationOn color="primary" />
              </Grid>
              <Grid item style={{ flex: "1 1 auto" }}>
                <Typography variant="caption">Origin</Typography>
                <Typography variant="subtitle1">{record.pickupTown}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container>
              <Grid item>
                <div
                  style={{
                    margin: "0 11px",
                    border: "2px solid #ccc",
                    height: "20px"
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <LocationOn color="primary" />
              </Grid>
              <Grid item>
                <Typography variant="caption">Destination</Typography>
                <Typography variant="subtitle1" style={{ flex: "1 1 auto" }}>
                  {record.dropoffTown}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
