import React from "react";
import { Admin, Resource } from "react-admin";

import { dataProvider } from "./providers";
import { client } from "./providers/client";
import { authProvider } from "./providers/auth";
import { theme } from "./theme";
import { getResources } from "./resource";
import Login from "./auth/login";
import { Layout } from "./layout";

const App = () => (
  <Admin
    theme={theme}
    loginPage={Login}
    authProvider={authProvider(client)}
    dataProvider={dataProvider}
    layout={Layout}
  >
    {user => getResources(user).map(resource => [<Resource {...resource} />])}
  </Admin>
);

export default App;
