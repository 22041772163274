import { Grid } from "@material-ui/core";
import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  NumberInput,
  Edit,
  Title
} from "react-admin";
import { Conditionally } from "../components/conditionally";

import LocationInput, {
  GoogleMapsAutocompleteLoader
} from "../components/location-input";
import { conditions } from "./constants";

export const CreatePrice = props => {
  return (
    <Create title="New Price" {...props}>
      <PriceForm />
    </Create>
  );
};

export const EditPrice = props => {
  return (
    <Edit title={<Title title={"name"} />} {...props}>
      <PriceForm />
    </Edit>
  );
};

const PriceForm = props => (
  <SimpleForm {...props}>
    <GoogleMapsAutocompleteLoader />
    <TextInput variant="outlined" label="Name" source="name" fullWidth />
    <RadioButtonGroupInput
      variant="outlined"
      type="Type"
      source="condition"
      choices={conditions}
      fullWidth
    />
    <NumberInput
      source="priority"
      label="Priority"
      variant="outlined"
      size="large"
      fullWidth
    />
    <FormDataConsumer>
      {({ formData }) => {
        return (
          <>
            <Conditionally
              when={formData.condition === "country"}
              render={() => (
                <ReferenceInput
                  reference="admin/countries"
                  source="countryId"
                  variant="outlined"
                  fullWidth
                  filterToQuery={text => ({
                    search: {
                      name: text
                    }
                  })}
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              )}
            />

            <Conditionally
              when={formData.condition === "event"}
              render={() => (
                <ReferenceInput
                  reference="admin/events"
                  source="eventId"
                  variant="outlined"
                  fullWidth
                  filterToQuery={text => ({
                    search: {
                      name: text
                    }
                  })}
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              )}
            />

            <Conditionally
              when={["event", "radius"].includes(formData.condition)}
              render={() => (
                <Grid container spacing={1}>
                  <Grid item sm={12} md={8}>
                    <LocationInput label="Origin" source="origin" fullWidth />
                  </Grid>

                  <Grid item sm={12} md={4}>
                    <NumberInput
                      source="originRadius"
                      label="Radius (m)"
                      variant="outlined"
                      size="large"
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={12} md={8}>
                    <LocationInput
                      label="Destination"
                      source="destination"
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={12} md={4}>
                    <NumberInput
                      source="destinationRadius"
                      label="Radius (m)"
                      variant="outlined"
                      size="large"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
            />
          </>
        );
      }}
    </FormDataConsumer>

    <TextInput source="currency" variant="outlined" fullWidth />

    <NumberInput
      label="Base Price"
      source="base"
      variant="outlined"
      fullWidth
    />

    <NumberInput label="Max Price" source="max" variant="outlined" fullWidth />

    <NumberInput label="Min Price" source="min" variant="outlined" fullWidth />

    <NumberInput
      label="Minimum Denomination"
      source="denomination"
      variant="outlined"
      fullWidth
    />

    <NumberInput
      label="Transition Distance (km)"
      source="transition"
      variant="outlined"
      fullWidth
    />
  </SimpleForm>
);
