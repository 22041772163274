import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { BoxedShowLayout, RaBox } from "ra-compact-ui";
import {
  Show,
  TextField,
  DateField,
  ReferenceField,
  TopToolbar
} from "react-admin";
import { makeStyles } from "@material-ui/core";

import {
  ApproveVehicleButton,
  RejectVehicleButton
} from "../components/approve-buttons";

const useStyles = makeStyles({
  detailsBox: {
    paddingRight: "50px",
    borderRight: "solid thin",
    marginRight: "50px"
  }
});

const Title = props => {
  return <span>{props.record ? `Vehicle Registration` : "title"}</span>;
};
export const VehicleRegistrationShow = props => {
  const classes = useStyles();
  return (
    <Show title={<Title />} actions={<Actions />} {...props}>
      <BoxedShowLayout>
        <RaBox display="flex">
          <RaBox
            display="flex"
            flexWrap="wrap"
            flexGrow={4}
            className={classes.detailsBox}
          >
            <RaBox
              flex="0 0 100%"
              display="flex"
              justifyContent="space-between"
            >
              <TextField label="Registration ID Number" source="id" />
              <RegistrationStatusField label="Registration Status" />
            </RaBox>

            <RaBox
              flex="0 0 100%"
              display="flex"
              justifyContent="space-between"
            >
              <DateField label="Registered on" source="createdAt" />
              <TextField label="Vehicle" source="vehicle" />
            </RaBox>
          </RaBox>

          <RaBox display="inline-flex" flexDirection="column" flexGrow={1}>
            <ReferenceField
              label="Owner"
              source="driverId"
              reference="admin/users"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
          </RaBox>
        </RaBox>
        <ImagesField label="Images" />
      </BoxedShowLayout>
    </Show>
  );
};

export const Actions = props => {
  const { data } = props;
  return (
    <TopToolbar style={{ float: "right", display: "inline-block" }}>
      <ApproveVehicleButton vehicle={data} />
      <RejectVehicleButton vehicle={data} />
    </TopToolbar>
  );
};

const ImagesField = ({ record }) => {
  return (
    <Grid spacing={8} container>
      {record.images.map(image => (
        <Grid xs={4} item>
          <img alt="Vehicle" style={{ width: "100%" }} src={image} />
        </Grid>
      ))}
    </Grid>
  );
};

export const RegistrationStatusField = ({ record }) => {
  const registrationStatus = record["status"];
  return (
    <>
      <Typography variant="body1">
        {registrationStatus.toUpperCase()}
      </Typography>
    </>
  );
};

ImagesField.defaultProps = {
  addLabel: true
};

RegistrationStatusField.defaultProps = {
  addLabel: true
};
