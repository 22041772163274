import { MonetizationOn } from "@material-ui/icons";
import { CreatePrice, EditPrice } from "./create-edit";
import { PriceList } from "./list";
import { PriceDetail } from "./show";

export const prices = {
  name: "admin/prices",
  icon: MonetizationOn,
  list: PriceList,
  show: PriceDetail,
  create: CreatePrice,
  edit: EditPrice,
  options: { label: "Prices" }
};
