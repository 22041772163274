import { admin, companyAdmin } from "./roles";
import { trips } from "./trips";
import { searches } from "./searches";
import { broadcasts } from "./broadcasts";
import { commutes } from "./commutes";
import { requests } from "./requests";
import { locations } from "./locations";
import { companies } from "./companies";
import { users, employees } from "./users/index";
import { documents } from "./documents/index";
import { vehicleRegistration } from "./vechicle-registration";
import { promotions } from "./promotions";
import { balances } from "./balances";
import { deposits } from "./deposits";
import { withdraws } from "./withdraws";
import { adminDashboard } from "./dashboard";
import { companyAdminDashboard } from "./dashboard/company-admin";
import { vehicleModels } from "./vehicle";
import { prices } from "./prices";
import { events } from "./events";

export function getResources(user) {
  if (user?.internalRole === admin) {
    return adminResources;
  }
  if (user?.companyRole === companyAdmin) {
    return companyAdminResources;
  }
}

export const adminResources = [
  adminDashboard,
  trips,
  searches,
  requests,
  commutes,
  vehicleRegistration,
  vehicleModels,
  users,
  broadcasts,
  documents,
  documents,
  locations,
  companies,
  promotions,
  balances,
  deposits,
  withdraws,
  prices,
  events,
  { name: "admin/countries" }
];

export const companyAdminResources = [companyAdminDashboard, employees];
