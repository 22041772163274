import { DepositList } from "./list";
import { AttachMoney } from "@material-ui/icons";

export const deposits = {
  name: "admin/deposits",
  list: DepositList,
  icon: AttachMoney,
  options: {
    label: "Deposits"
  }
};
