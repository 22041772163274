import { MonetizationOn } from "@material-ui/icons";

import { CreateEvent, EditEvent } from "./create-edit";
import { EventList } from "./list";

export const events = {
  name: "admin/events",
  icon: MonetizationOn,
  list: EventList,
  create: CreateEvent,
  edit: EditEvent,
  options: { label: "Events" }
};
