import { DriveEtaOutlined } from "@material-ui/icons";

import { VehicleModelCreate } from "./create";
import { vehicleModelList } from "./list";
import { VehicleModelEdit } from "./edit";

export const vehicleModels = {
  name: "admin/models",
  icon: DriveEtaOutlined,
  create: VehicleModelCreate,
  list: vehicleModelList,
  edit: VehicleModelEdit,
  options: {
    label: "Vehicle Models"
  }
};
