export const APPROVE_DRIVER = "APPROVE_DRIVER";

export const userActions = {
  [APPROVE_DRIVER]: approveDriver
};

function approveDriver(url, params) {
  return {
    method: "PATCH",
    url: `admin/${url}/${params.id}/approve`,
    data: params.data
  };
}
