import { CommuteList } from "./list";
import { Timer } from "@material-ui/icons";

export const commutes = {
  name: "admin/commutes",
  icon: Timer,
  list: CommuteList,
  options: {
    label: "Commutes"
  }
};
