import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput
} from "react-admin";
import { CameraInput } from "../components/fields";

import LocationInput from "../components/location-input";

const industryOptions = [
  { id: "aerospace", name: "Aerospace" },
  { id: "transport", name: "Transport" },
  { id: "technology", name: "Technology" },
  { id: "agriculture", name: "Agriculture" },
  { id: "construction", name: "Construction" },
  { id: "education", name: "Education" },
  { id: "food", name: "Food" },
  { id: "health care", name: "Health care" },
  { id: "entertainment", name: "Entertainment" },
  { id: "energy and minerals", name: "Energy and Minerals" },
  { id: "manufacturing", name: "Manufacturing" },
  {
    id: "electronics and telecommunication",
    name: "Electronics and Telecommunication"
  }
];

export const CompanyCreate = props => {
  return (
    <Create title="Create Company" {...props}>
      <SimpleForm>
        <CameraInput label="Company Logo" source="logo" />
        <TextInput label="Name" source="name" fullWidth />
        <TextInput label="Legal name" source="legalName" fullWidth />
        <SelectInput source="industry" choices={industryOptions} fullWidth />
        <TextInput label="Email address" source="email" fullWidth />
        <ArrayInput label="Address (location)" source="addresses" fullWidth>
          <SimpleFormIterator>
            <LocationInput />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
