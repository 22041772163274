import React from "react";
import IframeResizer from "iframe-resizer-react";
import { Grid, Paper } from "@material-ui/core";

const dashboard = () => {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Paper>
          <IframeResizer
            id="overview"
            src="http://metabase.safarishare.co/public/dashboard/0214708f-9f3a-4e69-bdce-c1a75501cfeb"
            frameBorder={0}
            style={{ width: "1px", minWidth: "100%", minHeight: "450px" }}
            heightCalculationMethod="bodyScroll"
            scrolling
          />
        </Paper>
      </Grid>
      <Grid item>
        <Paper>
          <IframeResizer
            id="trends"
            src="https://metabase.safarishare.co/public/dashboard/e3b2d980-df06-4828-95c0-113815445203"
            frameBorder={0}
            style={{ width: "1px", minWidth: "100%" }}
            heightCalculationMethod="bodyScroll"
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export const adminDashboard = {
  name: "dashboard",
  list: dashboard
};
