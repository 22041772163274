import * as React from "react";
import GoogleMapReact from "google-map-react";

const containerStyle = {
  width: "100%",
  height: "600px"
};

export const Map = ({ points = [], circles = [], style = {} }) => {
  const ref = React.useRef();

  return (
    <div style={{ ...containerStyle, ...style }}>
      <GoogleMapReact
        ref={ref}
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
        defaultCenter={{ lat: 0, lng: 0 }}
        defaultZoom={10}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          const bounds = new maps.LatLngBounds();

          points.forEach(point => {
            const { lng, lat } = point;
            const marker = new maps.Marker({
              position: {
                lat,
                lng
              },
              map,
              title: "Hello World!"
            });
            bounds.extend(marker.getPosition());
          });

          circles.forEach(({ center, radius }) => {
            const { lng, lat } = center;
            const circle = new maps.Circle({
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0.35,
              map,
              center: {
                lat,
                lng
              },
              radius
            });

            bounds.union(circle.getBounds());
          });

          map.fitBounds(bounds);
        }}
      >
        {/* {points.map((point, index) => {
          const [lng, lat] = point.coordinates;
          return (
            <Marker key={index} lat={lat} lng={lng} time={point.createdAt} />
          );
        })} */}
      </GoogleMapReact>
    </div>
  );
};
