export const pendingDriver = "pending";
export const approvedDriver = "approved";
export const rejectedDriver = "rejected";

export const driverStatuses = [pendingDriver, approvedDriver, rejectedDriver];

export const driverStatusOptions = driverStatuses.map(status => ({
  id: status,
  name: status
}));
