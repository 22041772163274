import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  Edit,
  DateTimeInput
} from "react-admin";
import { CameraInput, ImageInput } from "../components/fields";
import LocationInput from "../components/location-input";
import { Title } from "../components/title";

export const CreateEvent = props => (
  <Create title="New Event" {...props}>
    <EventForm />
  </Create>
);

export const EditEvent = props => (
  <Edit title={<Title source="name" />} {...props}>
    <EventForm />
  </Edit>
);

const EventForm = props => (
  <SimpleForm {...props}>
    <CameraInput source="logo" />

    <TextInput variant="outlined" label="Name" source="name" fullWidth />

    <TextInput
      variant="outlined"
      label="Summary"
      source="summary"
      multiline
      fullWidth
    />

    <TextInput
      type="url"
      variant="outlined"
      label="Website"
      source="url"
      fullWidth
    />

    <ImageInput label="Banner" source="banner" />

    <LocationInput label="Venue" source="location" />

    <DateTimeInput source="start" variant="outlined" fullWidth />

    <DateTimeInput source="end" variant="outlined" fullWidth />
  </SimpleForm>
);
