import { SearchList } from "./list";
import { Search } from "@material-ui/icons";

export const searches = {
  name: "admin/searches",
  icon: Search,
  list: SearchList,
  options: {
    label: "Searches"
  }
};
