import { RequestList } from "./list";
import { QuestionAnswer } from "@material-ui/icons";
import { RequestShow } from "./show";

export const requests = {
  name: "admin/requests",
  icon: QuestionAnswer,
  list: RequestList,
  options: {
    label: "Requests"
  },
  show: RequestShow
};
