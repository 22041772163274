import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  SelectInput,
  TextInput
} from "react-admin";
import { AvatarField, RoleField } from "../components/fields";
import { InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { driverStatusOptions } from "./constants";

export const UserList = props => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      sort={{ field: "createdAt", order: "DESC" }}
      filters={<UserFilter />}
      title="User"
    >
      <Datagrid rowClick="show">
        <AvatarField source="avatar" />
        <TextField source="name" />
        <RoleField label="Status" />
        <TextField label="Role" source="role" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};

const UserFilter = props => (
  <Filter {...props}>
    <SelectInput
      source="role"
      choices={[
        { id: "admin", name: "Admin" },
        { id: "traveller", name: "Passengers" },
        { id: "driver", name: "Drivers" }
      ]}
    />
    <SelectInput source="driverStatus" choices={driverStatusOptions} />
    <SelectInput
      source="installationStatus"
      label="Status"
      choices={[
        { id: "uninstalled", name: "Uninstalled" },
        { id: "installed", name: "Installed" }
      ]}
    />
    <TextInput
      source="search.name"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search color="disabled" />
          </InputAdornment>
        )
      }}
      label="Search"
      alwaysOn
    />
    <TextInput source="search.phone" label="Search Phone" />
  </Filter>
);
