import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

export const VehicleModelEdit = props => {
  return (
    <Edit title="Edit Vehicle Model" {...props}>
      <SimpleForm>
        <TextInput disabled label="VehicleModel_Id" source="id" />

        <TextInput label="Make" source="make" />

        <TextInput label="Model" source="model" />

        <TextInput label="Year" source="year" />
      </SimpleForm>
    </Edit>
  );
};
