import { WithdrawList } from "./list";
import { AttachMoney } from "@material-ui/icons";

export const withdraws = {
  name: "admin/withdraws",
  list: WithdrawList,
  icon: AttachMoney,
  options: {
    label: "Withdraws"
  }
};
