import React, { useState, useRef } from "react";
import { useLogin, useNotify, Notification } from "react-admin";
import PhoneInput from "react-phone-input-2";
import firebase from "firebase/app";
import "firebase/auth";
import ReactCodeInput from "react-code-input";
import {
  Grid,
  Button,
  Avatar,
  CircularProgress,
  Typography,
  ThemeProvider
} from "@material-ui/core";
import "react-phone-input-2/lib/style.css";

import { client } from "../providers/client";
import { firebaseConfig } from "../config";
import { theme } from "../theme";

firebase.initializeApp(firebaseConfig);

export default function Login() {
  const login = useLogin();
  const notify = useNotify();

  const recaptchaRef = useRef();

  const [isCode, setIsCode] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sessionInfo, setSessionInfo] = useState("");
  const [code, setCode] = useState("");
  const [isRequestingCode, setIsRequestingCode] = useState(false);
  const [isCofirmingCode, setIsCofirmingCode] = useState(false);

  function getRecaptchaToken() {
    if (window.recaptchaVerifier) window.recaptchaVerifier.clear();

    recaptchaRef.current.innerHTML = `<div id="recaptcha-container"></div>`;
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible"
      }
    );

    return window.recaptchaVerifier.verify();
  }

  function onSubmitPhoneNumber(e) {
    e.preventDefault();

    if (!phoneNumber) {
      return notify("Phone Number cannot be blank!", "error");
    }

    setIsRequestingCode(true);

    getRecaptchaToken()
      .then(recaptchaToken =>
        client.post("/auth/admin/otp/request", {
          phone: `+${phoneNumber}`,
          recaptchaToken
        })
      )
      .then(response => {
        setIsCode(true);
        setSessionInfo(response.data.sessionInfo);
        setIsRequestingCode(false);
      })
      .catch(error => {
        setIsRequestingCode(false);
        notify(
          error?.response?.data?.message || "Something went wrong",
          "error"
        );
      });
  }

  function verifyCode() {
    if (!code) {
      notify("code must be provided", "error");
      return;
    }

    if (code.length < 6) {
      return notify("Enter all codes", "error");
    }

    if (!sessionInfo) {
      notify("session info is required", "error");
    }

    setIsCofirmingCode(true);

    client
      .post("/auth/admin/otp/verify", {
        code,
        sessionInfo
      })
      .then(response => {
        setIsCofirmingCode(false);
        login(response.data.token);
      })
      .catch(error => {
        setIsCofirmingCode(false);
        notify(error?.response?.data?.message);
      });
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={4}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ height: "100vh" }}
      >
        <Grid item>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Avatar style={{ width: 100, height: 100 }} src={`/logo192.png`} />
          </div>
        </Grid>

        <Grid item>
          <Typography variant="h5" align="center">
            {isCode ? "Enter the secret code sent" : "Enter Your Phone Number"}
          </Typography>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="row"
            spacing={2}
            style={{ width: "100vw" }}
            justify="center"
          >
            <Grid item xs={12} sm={6} md={4}>
              {isCode ? (
                <form
                  onSubmit={verifyCode}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                >
                  <ReactCodeInput
                    type="text"
                    fields={6}
                    onChange={value => setCode(value)}
                  />
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{ marginTop: 15, marginBottom: 15 }}
                    disabled={isCofirmingCode}
                  >
                    {isCofirmingCode ? (
                      <CircularProgress />
                    ) : (
                      <span>Verify Code</span>
                    )}
                  </Button>
                </form>
              ) : (
                <div>
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      border: "1px solid #6b7280",
                      height: "42px",
                      borderRadius: "0px"
                    }}
                    onlyCountries={["ug", "ke"]}
                    countryCodeEditable={false}
                    country={"ug"}
                    value={phoneNumber}
                    onChange={phone => setPhoneNumber(phone)}
                  />
                  <div ref={recaptchaRef} />
                  <Button
                    id="request-otp"
                    onClick={onSubmitPhoneNumber}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    style={{ marginTop: 15, marginBottom: 15 }}
                    disabled={isRequestingCode}
                  >
                    {isRequestingCode ? (
                      <CircularProgress color="primary" size={24} />
                    ) : (
                      <span>Login</span>
                    )}
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
          <Notification />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
