import React from "react";
import {
  SimpleForm,
  Create,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
  FormDataConsumer
} from "react-admin";

export const UserCreate = props => {
  return (
    <Create title="Create User" {...props}>
      <SimpleForm>
        <TextInput source="name" fullWidth />
        <TextInput source="email" fullWidth />
        <TextInput source="phone" fullWidth />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.companyRole !== "company-admin" && (
              <SelectInput
                source="internalRole"
                choices={[{ id: "admin", name: "Admin" }]}
                allowEmpty
                fullWidth
              />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.internalRole !== "admin" && (
              <SelectInput
                source="companyRole"
                choices={[{ id: "company-admin", name: "Company Admin" }]}
                allowEmpty
                fullWidth
              />
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.companyRole === "company-admin" && (
              <ReferenceInput
                label="Company"
                source="companyId"
                reference="admin/companies"
                fullWidth
              >
                <SelectInput validate={[required()]} optionText="name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>

        <TextInput source="password" type="password" fullWidth />
      </SimpleForm>
    </Create>
  );
};

export const EmployeeCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" fullWidth />
        <TextInput source="email" fullWidth />
        <TextInput source="phone" fullWidth />
        <SelectInput
          validate={[required()]}
          source="role"
          choices={[
            { id: "traveller", name: "Traveller" },
            { id: "driver", name: "Driver" }
          ]}
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};
