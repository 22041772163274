import { Group as UserIcon } from "@material-ui/icons";
import { UserList } from "./list";
import { UserEdit } from "./edit";
import { UserCreate, EmployeeCreate } from "./create";
import { ShowUser } from "./show";

export const users = {
  name: "admin/users",
  icon: UserIcon,
  list: UserList,
  edit: UserEdit,
  create: UserCreate,
  show: ShowUser,
  options: {
    label: "Users"
  }
};

export const employees = {
  name: "corporate/employees",
  create: EmployeeCreate,
  list: UserList,
  icon: UserIcon,
  options: {
    label: "Employees"
  }
};
