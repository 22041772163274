import { DirectionsCar } from "@material-ui/icons";

import { TripList } from "./list";
import { TripEdit } from "./edit";

export const trips = {
  name: "admin/trips",
  icon: DirectionsCar,
  list: TripList,
  edit: TripEdit,
  options: {
    label: "Trips"
  }
};
