import React from "react";
import {
  SimpleForm,
  DateTimeInput,
  SelectInput,
  Create,
  TextInput
} from "react-admin";

const segments = [
  { id: "all", name: "Everyone" },
  { id: "travellers", name: "Passengers" },
  { id: "drivers", name: "Drivers" },
  { id: "commuteDrivers", name: "Commute Drivers" },
  { id: "commutePassengers", name: "Commute Passengers" }
];

export const BroadcastCreate = props => (
  <Create title="Create Broadcast" {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="body" />
      <SelectInput source="userSegment" choices={segments} />
      <DateTimeInput source="schedule" />
    </SimpleForm>
  </Create>
);
