import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

export const LinearLine = ({ data, series, axis }) => {
  console.log(data, axis, series);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart width={500} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={axis.id} />
        <YAxis />
        <Tooltip />
        <Legend />

        {series.map(serie => (
          <Line type="monotone" dataKey={serie.id} stroke={serie.color} />
        ))}

        <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
      </LineChart>
    </ResponsiveContainer>
  );
};
