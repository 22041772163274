export const APPROVE_VEHICLE_REGISTRATION = "APPROVE_VEHICLE_REGISTRATION";

export const vehicleActions = {
  [APPROVE_VEHICLE_REGISTRATION]: approveVehicle
};

function approveVehicle(url, params) {
  return {
    method: "PATCH",
    url: `admin/${url}/${params.id}/approve`,
    data: params.data
  };
}
