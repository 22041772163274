import React from "react";
import { Datagrid, List, TextField, DateField } from "react-admin";
import { RegistrationStatusField } from "./show";

export const vehicleRegistrationList = props => (
  <List bulkActionButtons={false} title="Vehicle Registrations" {...props}>
    <Datagrid rowClick="show">
      <TextField label="Registration ID" source="id" />
      <RegistrationStatusField label="Registration Status" />
      <TextField label="Vehicle Plate Number" source="numberPlate" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);
