import React from "react";
import {
  DateField,
  SimpleForm,
  Edit,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  FormDataConsumer
} from "react-admin";
import { RoleField } from "../components/fields";

export const UserEdit = props => (
  <Edit title="Edit User" {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="name" />

      <RoleField label="Role" />

      <SelectInput
        source="internalRole"
        choices={[{ id: "admin", name: "Admin" }]}
        fullWidth
      />

      <SelectInput
        source="companyRole"
        choices={[{ id: "company-admin", name: "Company Admin" }]}
        fullWidth
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.companyRole === "company-admin" && (
            <ReferenceInput
              label="Company"
              source="companyId"
              reference="admin/companies"
              fullWidth
            >
              <SelectInput validate={[required()]} optionText="name" />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <DateField showTime source="createdAt" />
    </SimpleForm>
  </Edit>
);
