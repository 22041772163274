import { stringify } from "qs";
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
  GET_MANY,
  GET_MANY_REFERENCE,
  HttpError
} from "react-admin";
import { handleError } from "./client";
import { userActions } from "../users/actions";
import { vehicleActions } from "../vechicle-registration/actions";

const restActions = {
  [CREATE]: create,
  [DELETE]: destroy,
  [DELETE_MANY]: destroyMany,
  [GET_LIST]: getList,
  [GET_MANY]: getMany,
  [GET_MANY_REFERENCE]: getManyReference,
  [GET_ONE]: getOne,
  [UPDATE]: update,
  [UPDATE_MANY]: updateMany
};

const customActions = {
  users: userActions,
  vehicles: vehicleActions
};

export function restProvider(client) {
  return (type, resource, params) => {
    const getConfig =
      restActions[type] ||
      customActions[resource][type] ||
      vehicleActions[resource][type];

    if (!getConfig) {
      return Promise.reject(new HttpError("Unsupported action"));
    }

    const config = getConfig(resource, params);

    return client(config)
      .then(response => response.data)
      .catch(handleError);
  };
}

function getList(url, params) {
  return {
    method: "GET",
    url: `${url}?${stringify({
      ...params.pagination,
      ...params.sort,
      filter: params.filter
    })}`
  };
}

function getOne(url, params) {
  return { method: "GET", url: `${url}/${params.id}` };
}

function getMany(url, params) {
  const { ids } = params;

  return { method: "GET", url: `${url}?${stringify({ ids })}` };
}

function getManyReference(url, params) {
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  const query = {
    field,
    order,
    page,
    perPage,
    filter: {
      ...params.filter,
      [params.target]: params.id
    }
  };

  return { method: "GET", url: `${url}?${stringify(query)}` };
}

function create(url, params) {
  return { method: "POST", url, data: params.data };
}

function update(url, params) {
  return { method: "PUT", url: `${url}/${params.id}`, data: params.data };
}

function updateMany(url, params) {
  const { ids, data } = params;
  return { method: "PUT", url: `${url}?${stringify({ ids })}`, data };
}

function destroy(url, params) {
  return { method: "DELETE", url: `${url}/${params.id}` };
}

function destroyMany(url, params) {
  const { ids } = params;
  return { method: "DELETE", url: `${url}?${stringify({ ids })}` };
}
