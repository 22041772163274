import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  ReferenceField
} from "react-admin";

export const WithdrawList = props => (
  <List bulkActionButtons={false} title="Withdraws" {...props}>
    <Datagrid rowClick="show">
      <ReferenceField reference="admin/users" source="userId">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Method" source="method" />
      <TextField label="Currency" source="currency" />
      <NumberField label="Amount" source="amount" />
      <TextField label="Status" source="status" />
      <TextField label="Account" source="phoneNumber" />
      <DateField label="Created" source="createdAt" showTime />
    </Datagrid>
  </List>
);
