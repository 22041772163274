import React from "react";
import {
  Datagrid,
  List,
  TextField,
  ReferenceField,
  DeleteButton,
  ReferenceInput,
  SelectInput,
  Filter
} from "react-admin";

const CommuteFilter = props => (
  <Filter {...props}>
    <ReferenceInput label="User" source="userId" reference="admin/users">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      source="type"
      label="Type"
      choices={[
        { id: "driver", name: "Driver" },
        { id: "passenger", name: "Passenger" }
      ]}
    />
  </Filter>
);

export const CommuteList = props => (
  <List
    filters={<CommuteFilter />}
    {...props}
    title="Commutes"
    bulkActionButtons={false}
    sort={{ field: "createdAt", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <ReferenceField
        label="User"
        source="userId"
        reference="admin/users"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField label="type" source="type" />
      <TextField label="From" source="originAddress" />
      <TextField label="To" source="destinationAddress" />
      <TextField source="timeLeavingOrigin" label="Set Off" />
      <TextField source="timeLeavingDestination" label="Return" />
      <DeleteButton />
    </Datagrid>
  </List>
);
