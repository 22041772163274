import React from "react";
import {
  Datagrid,
  List,
  TextField,
  ReferenceField,
  Filter,
  SelectInput,
  DateField
} from "react-admin";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { statusOptions } from "./constants";

export const RequestList = props => (
  <List
    {...props}
    title="Requests"
    bulkActionButtons={false}
    sort={{ field: "createdAt", order: "DESC" }}
    filterDefaultValues={{ pickupTime: "future" }}
    filters={<RequestFilter />}
  >
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <ReferenceField
        label="Driver"
        source="driver.id"
        reference="admin/users"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Passenger"
        source="passenger.id"
        reference="admin/users"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="status" />
      <TextField label="From" source="pickupTown" />
      <TextField label="To" source="dropoffTown" />
      <DateField source="pickupTime" label="Set Off" showTime />
      <DateField source="createdAt" label="Created" showTime />
      <TextField source="price" label="Fare" />
    </Datagrid>
  </List>
);

export const TimestampField = ({ record, source }) => (
  <Typography variant="body1">{moment(record[source]).calendar()}</Typography>
);

export const RequestFilter = props => (
  <Filter {...props}>
    <SelectInput
      label="Pickup Time"
      source="pickupTime"
      choices={[
        { id: "future", name: "Future" },
        { id: "past", name: "Past" }
      ]}
      alwaysOn
    />
    <SelectInput label="Status" source="status" choices={statusOptions} />
  </Filter>
);
