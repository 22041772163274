import * as React from "react";
import { SelectField, Show, SimpleShowLayout } from "react-admin";
import { Box, Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { repeat } from "ramda";
import { Map } from "../components/map";

import { conditions } from "./constants";
import { Conditionally } from "../components/conditionally";
import { LinearLine } from "../charts/line";
import { AttributeField } from "../components/attribute";

const Title = record => <span>{record.name}</span>;

export const PriceDetail = props => {
  return (
    <Show {...props} title={<Title />}>
      <SimpleShowLayout>
        <Details />
        <Box marginTop={1} />
        <Prices />
        <Box marginTop={1} />
        <Radii />
      </SimpleShowLayout>
    </Show>
  );
};

const Radii = ({ record }) => {
  if (!["radius", "event"].includes(record.condition)) return <div />;
  return (
    <Card>
      <CardHeader title="Locations" />
      <Map
        points={[record.origin?.coordinates, record.destination?.coordinates]}
        circles={[
          {
            center: record.origin?.coordinates,
            radius: record.originRadius
          },
          {
            center: record.destination?.coordinates,
            radius: record.destinationRadius
          }
        ]}
        style={{ height: "250px" }}
      />
    </Card>
  );
};

const Details = ({ record }) => {
  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <AttributeField label="Name" value={record.name} />
          </Grid>

          <Grid item md={3}>
            <AttributeField
              label="Type"
              value={
                <SelectField
                  source="condition"
                  choices={conditions}
                  record={record}
                />
              }
            />
          </Grid>

          <Conditionally
            when={record.condition === "country"}
            render={() => (
              <Grid item md={3}>
                <AttributeField label="Country" value={record.country?.name} />
              </Grid>
            )}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

const Prices = ({ record }) => {
  return (
    <Card>
      <CardHeader title="Prices" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <AttributeField
              label="Base"
              value={`${record.currency} ${record.base}`}
            />
          </Grid>

          <Grid item md={3}>
            <AttributeField
              label="Min"
              value={`${record.currency} ${record.min}`}
            />
          </Grid>

          <Grid item md={3}>
            <AttributeField
              label="Max"
              value={`${record.currency} ${record.max}`}
            />
          </Grid>

          <Grid item md={3}>
            <AttributeField
              label="Transition Distance"
              value={`${record.transition}km`}
            />
          </Grid>
        </Grid>

        <Conditionally
          when={!!record}
          render={() => (
            <Box height={"300px"} paddingTop={2}>
              <LinearLine {...createPriceChart(record)} />
            </Box>
          )}
        />
      </CardContent>
    </Card>
  );
};

function createPriceChart(price) {
  const data = repeat(null, 550 / 5)
    .map((_, i) => i * 5)
    .map(distance => {
      const unitPrice = getUnitPrice(
        distance,
        price.min,
        price.max,
        price.transition
      );
      return {
        distance,
        price: calculatePrice(
          distance,
          1,
          unitPrice,
          price.base,
          price.denomination || 500
        )
      };
    });

  return {
    data,
    series: [{ id: "price", color: "blue" }],
    axis: { id: "distance" }
  };
}

function calculatePrice(
  distance,
  seats = 1,
  unitPrice,
  basePrice,
  smallestDenomination
) {
  const cost = distance * unitPrice + basePrice;
  const roundedCost =
    Math.ceil(cost / smallestDenomination) * smallestDenomination;

  return roundedCost * seats;
}

function getUnitPrice(
  distance,
  minUnitPrice,
  maxUnitPrice,
  transitionDistance
) {
  if (distance > transitionDistance) {
    return minUnitPrice;
  }

  const priceDelta = maxUnitPrice - minUnitPrice;
  const distanceDelta = transitionDistance - distance;

  return Math.ceil(
    minUnitPrice + (distanceDelta * priceDelta) / transitionDistance
  );
}
