import React from "react";
import {
  Datagrid,
  List,
  TextField,
  DateField,
  ReferenceField,
  Filter,
  SelectInput
} from "react-admin";
import { statusOptions } from "./constants";

export const TripList = props => {
  return (
    <List
      {...props}
      title="Trips"
      bulkActionButtons={false}
      sort={{ field: "createdAt", order: "DESC" }}
      filterDefaultValues={{ period: { departureTime: "future" } }}
      filters={<TripFilter />}
    >
      <Datagrid rowClick="show">
        <TextField label="ID" source="id" />
        <ReferenceField
          label="Driver"
          source="driverId"
          reference="admin/users"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField label="From" source="originAddress" />
        <TextField label="To" source="destinationAddress" />
        <TextField label="Vehicle Plate" source="vehicle" />
        <TextField label="Vehicle Model" source="vehicleModel" />
        <DateField source="departureTime" showTime />
        <TextField label="Status" source="status" />
      </Datagrid>
    </List>
  );
};

const TripFilter = props => (
  <Filter {...props}>
    <SelectInput
      label="Departure Time"
      source="period.departureTime"
      choices={[
        { id: "future", name: "Future" },
        { id: "past", name: "Past" }
      ]}
      alwaysOn
    />
    <SelectInput
      label="Type"
      source="nullable.commuteId"
      choices={[
        { id: "null", name: "Posted" },
        { id: "not-null", name: "Commute" }
      ]}
    />
    <SelectInput label="Status" source="status" choices={statusOptions} />
  </Filter>
);
