import { DriveEta } from "@material-ui/icons";
import { vehicleRegistrationList } from "./list";
import { VehicleRegistrationShow } from "./show";

export const vehicleRegistration = {
  name: "admin/vehicles",
  icon: DriveEta,
  list: vehicleRegistrationList,
  show: VehicleRegistrationShow,
  options: {
    label: "Vehicles"
  }
};
