import React from "react";
import { Datagrid, DateField, List, TextField } from "react-admin";

export const vehicleModelList = props => (
  <List
    bulkActionButtons={false}
    title="Vehicle Models"
    {...props}
    sort={{ field: "createdAt", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField label="Make" source="make" />
      <TextField label="Model " source="model" />
      <TextField label="Year" source="year" />
      <DateField label="Created At" source="createdAt" />
    </Datagrid>
  </List>
);
