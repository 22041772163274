import React from "react";
import { List, Datagrid, TextField, DateField } from "react-admin";

export const BroadcastList = props => (
  <List title="Broadcasts" bulkActionButtons={false} {...props}>
    <Datagrid rowClick="show">
      <TextField source="title" />
      <TextField source="body" />
      <TextField source="userSegment" />
      <DateField source="schedule" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);
