import { Notifications } from "@material-ui/icons";
import { BroadcastList } from "./list";
import { BroadcastCreate } from "./create";

export const broadcasts = {
  name: "admin/broadcasts",
  icon: Notifications,
  list: BroadcastList,
  create: BroadcastCreate,
  options: {
    label: "Broadcasts"
  }
};
