import React from "react";
import {
  Layout as ReactAdminLayout,
  AppBar as ReactAdminAppBar,
  UserMenu as ReactAdminUserMenu,
  usePermissions
} from "react-admin";
import { VerifiedUser } from "@material-ui/icons";
import { Grid, MenuItem } from "@material-ui/core";

export const Layout = props => <ReactAdminLayout {...props} appBar={AppBar} />;

const AppBar = props => <ReactAdminAppBar {...props} userMenu={<UserMenu />} />;

export const UserMenu = props => {
  const { loading, permissions } = usePermissions();

  return (
    <ReactAdminUserMenu label={permissions?.name} {...props}>
      <MenuItem>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <VerifiedUser />
          </Grid>
          <Grid item>{loading ? "Loading..." : permissions.name}</Grid>
        </Grid>
      </MenuItem>
    </ReactAdminUserMenu>
  );
};
