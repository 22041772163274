import React, { useState } from "react";
import { refreshView, showNotification } from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField
} from "@material-ui/core";
import { connect } from "react-redux";
import { APPROVE_DRIVER } from "../users/actions";
import { APPROVE_VEHICLE_REGISTRATION } from "../vechicle-registration/actions";
import { dataProvider } from "../providers";

export const ApproveDriverButton = connect(
  null,
  {
    refreshView,
    showNotification
  }
)(({ driver = {}, refreshView, showNotification }) => {
  if (driver.driverStatus !== "pending") {
    return <div />;
  }

  function handleClick() {
    dataProvider(APPROVE_DRIVER, "users", {
      id: driver.id,
      data: { driverStatus: "approved" }
    }).then(() => {
      showNotification("Driver approved");
      refreshView();
    });
  }

  return (
    <Button
      style={{ background: "#2196f3", color: "white" }}
      onClick={handleClick}
    >
      Approve
    </Button>
  );
});

export const RejectDriverButton = connect(
  null,
  {
    refreshView,
    showNotification
  }
)(({ driver = {}, refreshView, showNotification }) => {
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState("");

  if (driver.driverStatus === "rejected") {
    return <div />;
  }

  function onChange(event) {
    setValue(event.target.value);
  }

  function onSubmit() {
    dataProvider(APPROVE_DRIVER, "users", {
      id: driver.id,
      data: { driverStatus: "rejected", driverRejectionReason: value }
    }).then(() => {
      setOpen(false);
      showNotification("Driver Rejected");
      refreshView();
    });
  }

  return (
    <RejectDialog
      onChange={onChange}
      open={isOpen}
      onSubmit={onSubmit}
      value={value}
      showDialog={setOpen}
    />
  );
});

export const ApproveVehicleButton = connect(
  null,
  {
    refreshView,
    showNotification
  }
)(({ vehicle = {}, refreshView, showNotification }) => {
  if (vehicle.status !== "pending") {
    return <div />;
  }

  function handleClick() {
    dataProvider(APPROVE_VEHICLE_REGISTRATION, "vehicles", {
      id: vehicle.id,
      data: { status: "approved" }
    }).then(() => {
      showNotification("Vehicle approved");
      refreshView();
    });
  }

  return (
    <Button
      style={{ background: "#2196f3", color: "white" }}
      onClick={handleClick}
    >
      Approve
    </Button>
  );
});

export const RejectVehicleButton = connect(
  null,
  {
    refreshView,
    showNotification
  }
)(({ vehicle = {}, refreshView, showNotification }) => {
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState("");
  if (vehicle.status !== "pending") {
    return <div />;
  }

  function onChange(event) {
    setValue(event.target.value);
  }

  function onSubmit() {
    dataProvider(APPROVE_VEHICLE_REGISTRATION, "vehicles", {
      id: vehicle.id,
      data: { status: "rejected", rejectionReason: value }
    }).then(() => {
      setOpen(false);
      showNotification("Vehicle Registration Declined");
      refreshView();
    });
  }

  return (
    <RejectDialog
      onChange={onChange}
      open={isOpen}
      onSubmit={onSubmit}
      value={value}
      showDialog={setOpen}
    />
  );
});

const RejectDialog = ({ onChange, open, onSubmit, value = "", showDialog }) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => showDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent style={{ padding: "3rem" }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            type="text"
            placeholder="Enter Reason For Rejection"
            label="Rejection Comment"
            fullWidth
            onChangeCapture={onChange}
            value={value}
          />
          <DialogActions>
            <Button disabled={value === ""} onClick={onSubmit}>
              Submit
            </Button>
            <Button
              style={{ background: "red", color: "white" }}
              onClick={() => showDialog(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Button
        style={{ background: "red", marginLeft: ".5rem", color: "white" }}
        onClick={() => showDialog(true)}
      >
        Reject
      </Button>
    </>
  );
};
