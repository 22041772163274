import { Notifications } from "@material-ui/icons";
import { PromotionList } from "./list";
import { PromotionCreate } from "./create";

export const promotions = {
  name: "admin/promotions",
  icon: Notifications,
  list: PromotionList,
  create: PromotionCreate,
  options: {
    label: "Promotions"
  }
};
