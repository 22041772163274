import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";

const descriptionMapper = {
  id: "ID/Passport",
  license: "Driver's License"
};

export const DocumentGrid = props => {
  const documents = Object.keys(props.data || {}).map(key => props.data[key]);
  return documents.map(document => (
    <a
      style={{ color: "white" }}
      key={document.id}
      href={document.name}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Grid alignContent="center" spacing={2} container>
        <Grid item>
          <AttachFile color="primary" />
        </Grid>
        <Grid item>
          <Typography variant="caption">
            {descriptionMapper[document.key]}
          </Typography>
        </Grid>
      </Grid>
    </a>
  ));
};
