import React from "react";
import {
  Datagrid,
  FunctionField,
  List,
  SelectField,
  TextField
} from "react-admin";
import { conditions } from "./constants";

export const PriceList = props => (
  <List
    {...props}
    title="Prices"
    bulkActionButtons={false}
    sort={{ field: "priority", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <TextField source="priority" />
      <SelectField source="condition" choices={conditions} />
      <FunctionField label="Detail" render={record => record.country?.name} />
      <FunctionField
        source="currency"
        render={record => record.currency?.toUpperCase()}
      />
      <TextField source="base" label="Base Price" />
      <FunctionField
        label="Price Per Km"
        render={record =>
          `${record.currency} ${[record.min, record.max]
            .filter(d => d)
            .join(" - ") || 0}`
        }
      />
    </Datagrid>
  </List>
);
