import axios from "axios";
import { stringify } from "qs";
import { googleMapsApi } from "../config";

export const url = "https://maps.googleapis.com/maps/api/geocode/json";

export function reverseGeocode(location) {
  const query = stringify({
    latlng: `${location.lat},${location.lng}`,
    key: googleMapsApi
  });

  return axios
    .get(`${url}?${query}`)
    .then(response => {
      return response.data;
    })
    .then(({ results, status }) => {
      if (status !== "OK") {
        return Promise.reject(new Error("Couldn't find location"));
      }

      const [result] = results;

      return Promise.resolve(result.formatted_address);
    });
}

export function geocode(description) {
  const query = stringify({
    address: description,
    key: googleMapsApi
  });

  return axios
    .get(`${url}?${query}`)
    .then(response => {
      return response.data;
    })
    .then(({ results, status }) => {
      if (status !== "OK") {
        return Promise.reject(new Error("Couldn't find location"));
      }

      const [result] = results;

      return Promise.resolve({
        coordinates: result.geometry.location,
        address: result.formatted_address
      });
    });
}
