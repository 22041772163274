import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  FunctionField
} from "react-admin";

export const PromotionList = props => (
  <List bulkActionButtons={false} title="Promotions" {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="code" />
      <TextField source="userSegment" />
      <TextField source="credit" />
      <DateField source="createdAt" />
      <FunctionField
        label="Rewards"
        render={record => record?.rewards?.length || 0}
      />
    </Datagrid>
  </List>
);
