import React from "react";
import {
  Show,
  TextField,
  TopToolbar,
  ReferenceManyField,
  Pagination,
  Datagrid,
  DateField,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  FunctionField,
  TabbedShowLayoutTabs,
  NumberField,
  EditButton
} from "react-admin";
import { Typography, Grid } from "@material-ui/core";
import {
  AvatarField,
  DocumentsField,
  VehiclesField,
  CommutesField
} from "../components/fields";
import {
  ApproveDriverButton,
  RejectDriverButton
} from "../components/approve-buttons";
import { MapGrid } from "../components/map-grid";

export const approved = "approved";
export const rejected = "rejected";

export const ShowUser = props => {
  return (
    <Show title={<Title />} actions={<Actions />} {...props}>
      <TabbedShowLayout
        syncWithLocation={false}
        tabs={
          <TabbedShowLayoutTabs
            {...props}
            variant="scrollable"
            scrollButtons="on"
          />
        }
      >
        <Tab label="Details">
          <FunctionField
            addLabel={false}
            render={record => {
              return (
                <Grid container spacing={2} style={{ marginBottom: 16 }}>
                  <Grid xs={6} md={3} item>
                    <Typography variant={"caption"}>Phone Number</Typography>
                    <Typography variant={"body1"}>{record?.phone}</Typography>
                  </Grid>
                  {record?.email && (
                    <Grid xs={6} md={3} item>
                      <Typography variant={"caption"}>Email</Typography>
                      <Typography variant={"body1"}>{record?.email}</Typography>
                    </Grid>
                  )}
                </Grid>
              );
            }}
          />

          <CommutesField />
          <DocumentsField />
          <VehiclesField />

          <ReferenceManyField
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            reference="admin/locations"
            perPage={50}
            target="userId"
          >
            <MapGrid />
          </ReferenceManyField>
        </Tab>

        <Tab label="searches" path="searches">
          <ReferenceManyField
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            reference="admin/searches"
            target="userId"
            label=""
          >
            <Datagrid rowClick="show">
              <TextField label="ID" source="id" />
              <TextField label="From" source="originAddress" />
              <TextField label="To" source="destinationAddress" />
              <DateField source="createdAt" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="requests" path="requests">
          <ReferenceManyField
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            reference="admin/requests"
            target="userId"
            label=""
          >
            <Datagrid rowClick="show">
              <TextField source="status" />
              <ReferenceField
                label="Driver"
                source="driver.id"
                reference="admin/users"
              >
                <TextField source="name" />
              </ReferenceField>

              <TextField label="From" source="pickupTown" />
              <TextField label="To" source="dropoffTown" />
              <DateField source="pickupTime" label="Set Off" showTime />
              <DateField source="createdAt" label="Created" showTime />
              <TextField source="price" label="Fare" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="trips" path="trips">
          <ReferenceManyField
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            reference="admin/trips"
            target="driverId"
            label=""
          >
            <Datagrid rowClick="show">
              <TextField label="From" source="originAddress" />
              <TextField label="To" source="destinationAddress" />
              <TextField label="Vehicle Plate" source="vehicle" />
              <TextField label="Vehicle Model" source="vehicleModel" />
              <DateField source="departureTime" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="wallet" path="wallet">
          <ReferenceManyField
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            reference="admin/balances"
            target="userId"
            label=""
          >
            <Datagrid rowClick="show">
              <TextField label="Type" source="type" />
              <FunctionField
                label="Ref (#)"
                render={record => {
                  const { requestId, commissionId, withdrawId, depositId } =
                    record || {};
                  return requestId || commissionId || withdrawId || depositId;
                }}
              />
              <TextField label="Actual" source="actualAmount" />
              <TextField label="Available" source="availableAmount" />
              <TextField label="Minimum" source="minimumAmount" />
              <DateField label="Created" source="createdAt" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="deposits" path="deposits">
          <ReferenceManyField
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            reference="admin/deposits"
            target="userId"
            label=""
          >
            <Datagrid rowClick="show">
              <TextField label="Method" source="method" />
              <TextField label="Currency" source="currency" />
              <NumberField label="Amount" source="amount" />
              <TextField label="Status" source="status" />
              <TextField label="Account" source="phoneNumber" />
              <DateField label="Created" source="createdAt" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="withdraws" path="withdraws">
          <ReferenceManyField
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            reference="admin/withdraws"
            target="userId"
            label=""
          >
            <Datagrid rowClick="show">
              <TextField label="Method" source="method" />
              <TextField label="Currency" source="currency" />
              <NumberField label="Amount" source="amount" />
              <TextField label="Status" source="status" />
              <TextField label="Account" source="phoneNumber" />
              <DateField label="Created" source="createdAt" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const Title = ({ record }) => <span>{record && record.name}</span>;

const Actions = ({ data, basePath }) => {
  const { name, avatar, installationStatus, role, driverStatus } = data || {};
  return (
    <TopToolbar>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        spacing={4}
      >
        <Grid item>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
          >
            <Grid item>
              <AvatarField
                source="avatar"
                record={{ name, avatar, installationStatus }}
                size={"huge"}
              />
            </Grid>
            <Grid
              item
              justify="center"
              container
              direction="column"
              align="center"
            >
              <Typography style={{ flex: 1 }} variant="h4">
                {name}
              </Typography>
              <Typography
                style={{ flex: 1 }}
                variant="subtitle1"
                align="center"
              >
                {[driverStatus, role].filter(d => d).join(" ")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ApproveDriverButton driver={data} />
          <RejectDriverButton driver={data} />
          <EditButton record={data} basePath={basePath} />
        </Grid>
      </Grid>
    </TopToolbar>
  );
};
