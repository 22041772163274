import { UserLocationsList } from "./list";
import { Map } from "@material-ui/icons";

export const locations = {
  name: "admin/locations",
  icon: Map,
  list: UserLocationsList,
  options: {
    label: "Locations"
  }
};
