import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CheckIcon from "@material-ui/icons/Check";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { inviteLinkUrl } from "../config";

const Dashboard = ({ permissions }) => {
  const [copied, setCopied] = useState(false);

  return (
    <Card style={{ height: "100%", padding: "16px" }}>
      <h1>{permissions?.companies[0]?.name}</h1>
      <h5 style={{ paddingBottom: "-10px" }}>Invitation Link</h5>
      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <div
          style={{ backgroundColor: "#303030", padding: "8px" }}
        >{`${inviteLinkUrl}/${permissions?.companies[0]?.uniqueToken}`}</div>
        <CopyToClipboard
          text={`${inviteLinkUrl}/${permissions?.companies[0]?.uniqueToken}`}
          onCopy={() => {
            setCopied(true);
            setTimeout(function() {
              setCopied(false);
            }, 2000);
          }}
        >
          <div style={{ cursor: "pointer", marginLeft: "5px" }}>
            <FileCopyIcon />
          </div>
        </CopyToClipboard>
        {copied ? (
          <span style={{ color: "green" }}>
            <CheckIcon />
            Invitaion Link Copied.
          </span>
        ) : null}
      </div>
    </Card>
  );
};

export const companyAdminDashboard = {
  name: "corporate/dashboard",
  list: Dashboard,
  options: {
    label: "Dashboard"
  }
};
