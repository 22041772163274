import React from "react";
import {
  Datagrid,
  List,
  TextField,
  ReferenceField,
  DateField
} from "react-admin";

export const UserLocationsList = props => (
  <List
    {...props}
    title="Locations"
    bulkActionButtons={false}
    sort={{ field: "createdAt", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <ReferenceField
        label="User"
        source="userId"
        reference="admin/users"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField label="userId" source="userId" />
      <DateField label="createdAt" source="createdAt" showTime />
    </Datagrid>
  </List>
);
