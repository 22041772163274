import React from "react";
import {
  SimpleForm,
  NumberInput,
  SelectInput,
  Create,
  TextInput
} from "react-admin";

export const segments = [
  { id: "all", name: "Everyone" },
  { id: "travellers", name: "Passengers" },
  { id: "drivers", name: "Drivers" },
  { id: "commuteDrivers", name: "Commute Drivers" },
  { id: "commutePassengers", name: "Commute Passengers" }
];

export const PromotionCreate = props => (
  <Create title="Create Promotion" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="code" />
      <SelectInput
        source="type"
        choices={[
          { id: "credit", name: "Credit" },
          { id: "draw", name: "Draw" }
        ]}
      />
      <SelectInput
        source="userSegment"
        choices={[
          { id: "driver", name: "Driver" },
          { id: "traveller", name: "Traveler" }
        ]}
      />
      <NumberInput source="credit" />
    </SimpleForm>
  </Create>
);
