import { CompanyList } from "./list";
import { CompanyCreate } from "./create";
import { HomeWork } from "@material-ui/icons";

export const companies = {
  name: "admin/companies",
  icon: HomeWork,
  create: CompanyCreate,
  list: CompanyList,
  options: {
    label: "Companies"
  }
};
