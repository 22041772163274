const env = typeof window === "undefined" ? process.env : window.env;

export const backendUrl = env.REACT_APP_BACKEND_URL;

export const inviteLinkUrl = env.REACT_APP_INVITE_URL;

export const googleMapsApi = env.REACT_APP_GOOGLE_MAP_API_KEY;

export const firebaseApiKey = env.REACT_APP_FIREBASE_API_KEY;
export const firebaseAuthDomain = env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const firebaseDatabaseUrl = env.REACT_APP_FIREBASE_DATABASE_URL;
export const firebaseProjectId = env.REACT_APP_FIREBASE_PROJECT_ID;
export const firebaseSenderId = env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const firebaseAppId = env.REACT_APP_FIREBASE_APP_ID;

export const firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: firebaseAuthDomain,
  databaseURL: firebaseDatabaseUrl,
  projectId: firebaseProjectId,
  storageBucket: "",
  messagingSenderId: firebaseSenderId,
  appId: firebaseAppId
};
