import React from "react";

import { ArrayStringField, AvatarField } from "../components/fields";
import { List, Datagrid, TextField, EmailField } from "react-admin";

export const CompanyList = props => {
  return (
    <List title="Companies" bulkActionButtons={false} {...props}>
      <Datagrid rowClick="show">
        <AvatarField source="logo" />
        <TextField source="name" />
        <EmailField source="email" />
        <TextField source="legalName" />
        <TextField source="industry" />
        <ArrayStringField label="Locations" source="corporateAddress" />
      </Datagrid>
    </List>
  );
};
