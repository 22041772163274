import React from "react";
import { Grid, Typography } from "@material-ui/core";

export const AttributeField = ({ label, value }) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="caption">{label}</Typography>
      </Grid>
      <Grid item>
        {typeof value === "string" ? (
          <Typography variant="body1">{value}</Typography>
        ) : (
          value
        )}
      </Grid>
    </Grid>
  );
};
